import { Button, Flex, Grid, Heading, Icon, IconButton } from '@chakra-ui/react'
import { Label } from 'components/Label'
import { useShiftedDaysInWeek } from 'components/TimePick'
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { BiTrash } from 'react-icons/bi'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { BorderWrapper } from '../../../BorderWrapper'
import { TimeSelect } from '../components'
import useTranslation from 'next-translate/useTranslation'

interface Props {
  dayInWeek: number
  control: Control<ShopProductInput>
  watch: UseFormWatch<ShopProductInput>
  setValue: UseFormSetValue<ShopProductInput>
}

export const DayAvailability: React.FC<Props> = ({ dayInWeek, control, watch, setValue }) => {
  const { t } = useTranslation('backOffice')
  const { getDayTranslation } = useShiftedDaysInWeek()
  const slotSettings = watch('BookingTemplateSettings.SlotSettings')
  const daySettings = slotSettings
    ?.map((s, index) => ({ ...s, index }))
    ?.filter((s) => s.dayInWeek === dayInWeek)
    .sort(({ fromTime: a }, { fromTime: b }) => (a > b ? 1 : -1))

  const lastDaySetting = daySettings?.[daySettings.length - 1]

  const handleAddSlot = () => {
    const newSlot = {
      dayInWeek,
      fromTime: lastDaySetting?.toTime || 0,
      toTime: Math.min(lastDaySetting?.toTime + 60 || 60, 24 * 60),
      isEnabled: true,
    }
    setValue('BookingTemplateSettings.SlotSettings', [...slotSettings, newSlot])
  }

  const handleDeleteSlot = (index: number) => {
    const newSlotSettings = slotSettings?.filter((_, i) => i !== index)
    setValue('BookingTemplateSettings.SlotSettings', newSlotSettings)
  }

  return (
    <BorderWrapper
      p={{ base: 2, md: 3 }}
      py={{ base: 4, md: 8 }}
      pl={{ base: 4, md: 10 }}
      as={Flex}
      flexDir="column"
      alignItems="stretch"
      gap={{ base: 2, md: 4 }}
    >
      <Heading size="sm">{getDayTranslation(dayInWeek)}</Heading>
      {daySettings?.map(({ fromTime, index }, itemIndex) => (
        <Grid key={fromTime} templateColumns="1fr 1fr auto" gap={2}>
          <Label>{t('Bookings.from')}</Label>
          <Label>{t('Bookings.to')}</Label>
          <div />
          <TimeSelect
            name={`BookingTemplateSettings.SlotSettings.${index}.fromTime`}
            control={control}
          />
          <TimeSelect
            name={`BookingTemplateSettings.SlotSettings.${index}.toTime`}
            control={control}
          />
          <IconButton
            aria-label="Remove"
            variant="unstyled"
            borderRadius="md"
            px="2.5"
            icon={<Icon as={BiTrash} width="6" height="6" />}
            onClick={() => handleDeleteSlot(index)}
            {...(itemIndex === 0 && {
              visibility: 'hidden',
            })}
          />
        </Grid>
      ))}
      <Button alignSelf="center" onClick={handleAddSlot}>
        {t('Bookings.addSlot')}
      </Button>
    </BorderWrapper>
  )
}
