import { Heading, Stack } from '@chakra-ui/layout'
import { Switch } from '@chakra-ui/react'
import { Label } from 'components/Label'
import { Control, UseFormRegister } from 'react-hook-form'
import { ShopProductInput } from 'src/generated/graphql-frontend'
import { ReactSelectController } from './ReactSelectController'
import useTranslation from 'next-translate/useTranslation'

const INTERVAL_MINUTES = 15
const BREAK_INTERVAL_MINUTES = 5

const formatDuration = (duration: number) => {
  if (duration >= 60) {
    const hours = Math.floor(duration / 60)
    const minutes = duration % 60
    return `${hours}h ${minutes}min`
  }
  return `${duration}min`
}

const durationOptions = Array.from(
  { length: Math.ceil((8 * 60) / INTERVAL_MINUTES) },
  (_, i) => i * INTERVAL_MINUTES + INTERVAL_MINUTES
).map((value) => ({
  value,
  label: formatDuration(value),
}))

const breakOptions = Array.from(
  { length: Math.ceil(65 / BREAK_INTERVAL_MINUTES) },
  (_, i) => i * BREAK_INTERVAL_MINUTES
).map((value) => ({
  value,
  label: formatDuration(value),
}))

const minimumMinutesInAdvanceOptions = [
  {
    value: 0,
    label: '0 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 8 * 60,
    label: '8 hours',
  },
  {
    value: 24 * 60,
    label: '1 day',
  },
  {
    value: 2 * 24 * 60,
    label: '2 days',
  },
  {
    value: 3 * 24 * 60,
    label: '3 days',
  },
  {
    value: 7 * 24 * 60,
    label: '1 week',
  },
  {
    value: 14 * 24 * 60,
    label: '2 weeks',
  },
  {
    value: 30 * 24 * 60,
    label: '1 month',
  },
]

export interface Props {
  // /**
  //  * Number of minutes that the session lasts
  //  */
  // duration: number
  // /**
  //  * Number of minutes between the end of a session and the start of the next one
  //  */
  // breakInMinutes: number
  // /**
  //  * Number of people that can participate in the session at the same time
  //  */
  // capacity: number
  // /**
  //  * Amount of days in advance that the session can be booked
  //  */
  // daysInAdvance: number
  // /**
  //  * Whether the event can be booked
  //  */
  // isEnabled: boolean
  control: Control<ShopProductInput>
  register: UseFormRegister<ShopProductInput>
}

export const SlotsSettings = ({ control, register }: Props) => {
  const { t } = useTranslation('backOffice')
  return (
    <Stack>
      <Heading size="md">{t('Bookings.slotSettings')}</Heading>
      <Stack direction="row" alignItems="baseline">
        <Label>{t('Bookings.enableBooking')}</Label>
        <Switch {...register('BookingTemplateSettings.isEnabled')} colorScheme="green" size="md" />
      </Stack>
      <Stack direction="row" alignItems="flex-end">
        <Stack flex="1">
          <Label>{t('Bookings.slotDuration')}</Label>
          <ReactSelectController
            name="BookingTemplateSettings.slotDurationInMins"
            control={control}
            options={durationOptions}
          />
        </Stack>
        <Stack flex="1">
          <Label>{t('Bookings.minimumNotice')}</Label>
          <ReactSelectController
            name="BookingTemplateSettings.minimumNoticeInMinutes"
            control={control}
            options={minimumMinutesInAdvanceOptions}
          />
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="flex-end">
        {/* <Stack flex="1">
        <Label>Capacity</Label>
        <ReactSelectController
          name="BookingTemplateSettings.participantsPerSlot"
          control={control}
          options={Array.from({ length: 50 }, (_, i) => i + 1).map((value) => ({
            value,
            label: value.toString(),
          }))}
        />
      </Stack> */}
        <Stack flex="1">
          <Label>{t('Bookings.breakBetweenSlots')}</Label>
          <ReactSelectController
            name="BookingTemplateSettings.slotBreakInMins"
            control={control}
            options={breakOptions}
          />
        </Stack>
        <Stack flex="1">
          <Label>{t('Bookings.maximumDaysInAdvance')}</Label>
          <ReactSelectController
            name="BookingTemplateSettings.daysInAdvance"
            control={control}
            options={Array.from({ length: 90 }, (_, i) => i + 1).map((value) => ({
              value,
              label: value.toString(),
            }))}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
