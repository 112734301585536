import React from 'react'
import { Grid, useToast } from '@chakra-ui/react'
import { /*BiCustomize,*/ BiDownload } from 'react-icons/bi'
import { /*BsStars,*/ BsCalendar2Date } from 'react-icons/bs'
import type { IconType } from 'react-icons/lib'
import { RiPriceTag2Line /*, RiVideoChatLine*/ } from 'react-icons/ri'
import { ProductTypeEnum } from 'src/generated/graphql-frontend'
import useTranslation from 'next-translate/useTranslation'
import { CardWithIconButton } from 'components/CardWithIconButton'

export const useProductCategories = () => {
  const { t } = useTranslation('common')
  const productCategories = React.useMemo<
    {
      key: keyof typeof ProductTypeEnum
      label: string
      icon: IconType
    }[]
  >(
    () => [
      {
        key: 'Download',
        label: t('ProductType.Download'),
        icon: BiDownload,
      },
      // {
      //   // TODO: Zoom integration
      //   key: 'Videocall',
      //   label: 'Video call',
      //   icon: RiVideoChatLine,
      // },
      {
        key: 'Booking',
        label: t('ProductType.Booking'),
        icon: BsCalendar2Date,
      },
      {
        key: 'Physical',
        label: t('ProductType.Physical'),
        icon: RiPriceTag2Line,
      },
      // {
      //   // TODO: Location / Zoom link provided by host in the form
      //   key: 'Class',
      //   label: 'Class / lecture',
      //   icon: BsStars,
      // },
      // {
      //   key: 'Digital',
      //   label: 'Digital product',
      //   icon: BiCustomize,
      // },
    ],
    []
  )

  return productCategories
}

interface Props {
  name: string
  value?: string | null
  onClick?(): void
  onChange?(newValue: string): void
}

export const ProductTypePick: React.FC<Props> = ({ value, onChange }) => {
  const productCategories = useProductCategories()
  const toast = useToast()
  const cantChange = React.useCallback(
    () =>
      toast({
        position: 'top-right',
        // eslint-disable-next-line quotes
        title: "Can't change product category",
        description: 'Product category cannot be changed after it was published.',
        status: 'warning',
        variant: 'left-accent',
      }),
    []
  )
  return (
    <Grid
      gap={{ base: 2, sm: 4 }}
      templateColumns={{
        base: '1fr 1fr',
        md: 'repeat(auto-fit, minmax(12.5rem, 1fr))',
      }}
    >
      {productCategories?.map(({ key, label, icon }) => (
        <CardWithIconButton
          key={key}
          icon={icon}
          label={label}
          groupHasValue={!!value}
          isChecked={value === key}
          onClick={onChange ? () => onChange(key) : cantChange}
        />
      ))}
    </Grid>
  )
}
