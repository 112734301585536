import { menuAnatomy as parts } from '@chakra-ui/anatomy'
import type {
  PartsStyleFunction,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const baseStyleList: SystemStyleFunction = (props) => ({
  bg: mode('#fff', 'gray.700')(props),
  boxShadow: mode('sm', 'dark-lg')(props),
  color: 'inherit',
  minW: '3xs',
  py: '5',
  zIndex: 'dropdown',
  borderRadius: 'lg',
  borderWidth: '1px',
  borderColor: 'gray.300',
})

// This is the style for chakra-react-select too (ReactSelect.tsx)
const baseStyleItem: SystemStyleFunction = (props) => ({
  py: '0.4rem',
  px: '5',
  transitionProperty: 'background',
  transitionDuration: 'ultra-fast',
  transitionTimingFunction: 'ease-in',
  _selected: {
    bg: mode('primary.500', 'primary.200')(props),
    color: mode('white', 'gray.800')(props),
  },
  _focus: {
    bg: mode('gray.100', 'whiteAlpha.100')(props),
    color: mode('gray.900', 'gray.800')(props),
  },
  _active: {
    bg: mode('gray.200', 'whiteAlpha.200')(props),
  },
  _expanded: {
    bg: mode('gray.100', 'whiteAlpha.100')(props),
  },
  _disabled: {
    opacity: 0.4,
    cursor: 'not-allowed',
  },
})

const baseStyleGroupTitle: SystemStyleObject = {
  mx: 4,
  my: 2,
  fontWeight: 'semibold',
  fontSize: 'md',
}

const baseStyleCommand: SystemStyleObject = {
  opacity: 0.6,
}

const baseStyleDivider: SystemStyleObject = {
  border: 0,
  borderBottom: '1px solid',
  borderColor: 'inherit',
  my: '0.5rem',
  opacity: 0.6,
}

const baseStyleButton: SystemStyleObject = {
  transitionProperty: 'common',
  transitionDuration: 'normal',
}

const baseStyle: PartsStyleFunction<typeof parts> = (props) => ({
  button: baseStyleButton,
  list: baseStyleList(props),
  item: baseStyleItem(props),
  groupTitle: baseStyleGroupTitle,
  command: baseStyleCommand,
  divider: baseStyleDivider,
})

export default {
  parts: parts.keys,
  baseStyle,
}
